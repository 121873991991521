








import { Component, Mixins } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../../support/mixins'
import { ProfileWrapper } from '../../../shared'
import { ACCOUNT_VIEW_CONFIG_MAP, ACCOUNT_VIEW_KEY } from '../Account.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<EmptyAccount>({
  name: 'EmptyAccount',
  components: {
    ProfileWrapper
  },
  created () {
    this.config = this.getComponentConfig(ACCOUNT_VIEW_KEY, { ...ACCOUNT_VIEW_CONFIG_MAP })
  }
})
export class EmptyAccount extends Mixins<StructureConfigurable>(StructureConfigurable) {}

export default EmptyAccount
